/* NOTE: This attribute is required for animated wind to render properly */
.mapboxgl-canvas {
	position: absolute;
}

.mapboxgl-popup {
	max-width: 400px;
	font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.mapboxgl-ctrl-traffic {
	z-index: 9999;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23333333%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%22-2%20-2%2022%2022%22%3E%0D%0A%3Cpath%20d%3D%22M13%2C4.1L12%2C3H6L5%2C4.1l-2%2C9.8L4%2C15h10l1-1.1L13%2C4.1z%20M10%2C13H8v-3h2V13z%20M10%2C8H8V5h2V8z%22%2F%3E%0D%0A%3C%2Fsvg%3E');
}

.mapboxgl-ctrl-map {
	z-index: 9999;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23333333%22%20viewBox%3D%22-10%20-10%2060%2060%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%3E%3Cg%3E%3Cpath%20d%3D%22m25%2031.640000000000004v-19.766666666666673l-10-3.511666666666663v19.766666666666666z%20m9.140000000000008-26.640000000000004q0.8599999999999923%200%200.8599999999999923%200.8600000000000003v25.156666666666666q0%200.625-0.625%200.783333333333335l-9.375%203.1999999999999993-10-3.5133333333333354-8.906666666666668%203.4383333333333326-0.2333333333333334%200.07833333333333314q-0.8616666666666664%200-0.8616666666666664-0.8599999999999994v-25.156666666666663q0-0.625%200.6233333333333331-0.7833333333333332l9.378333333333334-3.198333333333334%2010%203.5133333333333336%208.905000000000001-3.4383333333333344z%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}

.mapboxgl-compact {
	display: none;
}

.mapboxgl-ctrl {
	margin-bottom:36px;
}

.map-bottom-panel-eri-legend{
	margin:8px 0 0 0;
}

.map-bottom-panel-eri-legend-scenario{
	display:flex;
}

.map-bottom-panel-eri-legend-label {
	font-weight: bold;
	size:.5em;
	margin:8px 0 0 0;
}

.map-bottom-panel-legend-scenario-color {
	height:20px;
	width:20px;
	margin-right:8px;
}

.map-tab-container {
	height: 100%;
}
.map-tab-container .tab-content {
	margin: 1.5rem 0 0;
	padding: 0 !important;
}
.map-tab-container .tab-pane {
	position: relative;
    height: 100%;
}
.event-news-container,
.eri-container {
	max-height: 100%;
	overflow-y: auto;
}

/* eri tables */
.eri-table.table td {
	border-bottom: solid 2px #6c757d;
	padding: 0.5rem 0.75rem;
}
.eri-table.table th {
	border-bottom: solid 2px #6c757d;
	border-top: solid 2px #6c757d;
	background: #6c757d;
	padding: 0.5rem 0.75rem;
}

